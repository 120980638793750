<template>
    <div class="dashboard py-5 py-lg-6">
        <section>
            <div class="row gy-4">
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
                <div class="col-lg-8">
                    <div class="mb-3 col-sm-10 col-lg-12" v-if="activeUser">
                        <div class="media d-sm-flex d-block">
                            <img :src="absoluteUrl(activeUser.image)" class="user-photo mr-3 rounded-circle" alt="user-photo">
                            <div class="media-body">
                                <h3 class="mb-0 mt-3 font-weight-bold">{{activeUser.first_name}} {{activeUser.last_name}}</h3>
                                <p class="mb-0"> {{activeUser.email}}</p>
                                <table class="table profile-table bg-transparent table-borderless">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Country</th>
                                            <td v-if="activeUser.country">{{activeUser.country.name}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Joined since</th>
                                            <td colspan="2">{{activeUser.created_at | month_year}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card">
                        <div class="bg-holder bg-card" style="background-image:url(assets/img/icons/spot-illustrations/corner-1.png);"></div>
                        <!--/.bg-holder-->
                        <div class="card-body position-relative">
                            <h5 class="text-success font-weight-bold">Checkout Special Offer!</h5>
                            <p class="fs--1 mb-0">Time is running out ⚡ your handcrafted custom logo design® 50% OFF | Limited spots</p>
                            <a class="btn btn-success fs-1 font-weight-bold mt-3 mr-1 ps-0" target="_blank" :href="absoluteUrl('/logo-design')">
                                Logo Design
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                            <a class="btn btn-outline-light fs-1 font-weight-bold text-success mt-3 ps-0" target="_blank" :href="absoluteUrl('/categories')">
                                All Services
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                 <div class="col-lg-4 col-12">
                    <div class="card-fill card">
                        <div class="card-header py-3 d-flex justify-content-between">
                            <h5 class="card-header-title mb-0 font-weight-bold">Recent Activity</h5>
                            <router-link class="small text-success" to="/notifications">View all</router-link>
                        </div>
                        <div class="card-body">
                            <div class="list-group-flush list-group-activity list-group">
                                <div v-for="(notification, noIndex) in notifications" :key="noIndex" class="list-group-item">
                                    <div @click="navigateTo(notification)" class="row cursor-pointer">
                                        <div class="col-auto pr-0">
                                            <button :class="`btn-outline-${notification.type}`" class="btn btn-sm rounded-pill" type="button">
                                                <i class="mdi" :class="notification.icon"></i>
                                            </button>
                                        </div>
                                        <div class="ms-n2 col">
                                            <h6 class="mb-1 font-weight-bold">{{notification.title}}</h6>
                                            <p class="font-12 text-gray-700 mb-0">{{notification.text}}</p>
                                            <small class="text-muted">{{notification.created_at | date_ago}}</small>
                                        </div>
                                    </div>
                                </div>
                                <p v-if="!notifications.length"><em>Unread notifications show here</em></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row pt-5 pt-lg-6">
                <div class="col-12">
                    <order-table />
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import OrderTable from "@/components/order/OrderTable.vue"
import UserEmailVerification from "@/views/account/UserEmailVerification.vue"

export default {
    name: 'dashboard',
    components:{
        OrderTable,
        UserEmailVerification
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
        notifications(){
            return this.$store.state.notification.notifications.filter(nt => !nt.is_seen).slice(0,3)
        }
    },
    methods:{
        navigateTo(notif){
            if(!notif.is_seen){
                this.$store.dispatch("notification/markNotifAsRead", notif.id);
            }
            this.$router.push({path: notif.url });
        },
    },
    created(){
        this.$store.dispatch("fetchActiveUser");
    }

}
</script>

<style scoped>
.user-photo{
    width:9rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.2rem;
}

</style>
